<template>
    <div>
        <div class="terms_and_conditions"> 
            <a href="/termsAndConditions" class="conditions_terms front_of_all">
                Terminos y Condiciones , 
            </a>
            <a href="/privacyAdvice" class="conditions_terms">
                Aviso de Privacidad
            </a>
        </div>
    </div>
</template>

<script>

    export default {
        name:"TermsAndConditionsFooter",
        data(){
            return{

            }
        }
    }

</script>

<style>

.terms_and_conditions {
    text-align: center;
    background-color: var(--primary);
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100% !important;
    font-weight: 100;
    text-decoration: underline;
    z-index: 100;
  }
  
  .front_of_all {
    z-index: 100;
  }
  
  @media (min-width: 767px) {
    .terms_and_conditions {
      width: 400px;
    }
  }
  
  .conditions_terms {
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
  }

</style>