<template>

  <div id="base-body">

    <div class="topbar">
      <app-topbar-nav
        class="app-topbar-nav"
      ></app-topbar-nav>
    </div>

    <!-- @click.native="$refs['sidebar'].closeHamburger(); if(!isUserSideBarOpen) setIsUserSideBarOpen({status: false, timeout: 0});" -->


    <!-- Background Component -->
    <div id="main_background">
      <img src="@/assets/img/background.jpg" alt="">
    </div>


    <router-view></router-view>


    <!-- <WhatsappComponent /> -->
    <!-- <Chatbot :isMobileView="isMobileView" class="fixed bottom-3 right-3 sm:bottom-3 sm:right-20"></Chatbot>       -->
    <TermsAndConditionsFooter/>
    
  </div>
  <!-- app container closed -->

</template>

<script>

// import Chatbot from '@/components/Chatbot/Chatbot.vue';
// import WhatsappComponent from "@/components/SocialMedia/Whatsapp";

import TermsAndConditionsFooter from '@/components/TermsAndConditionsFooter.vue';
import { mapMutations } from "vuex";

export default {
  name: "Base",
  data: () => ({
    collapsed: false,
    customizer: false,
    boxLayout: false,
    darkMode: false,
    themeColor: ""
  }),
  components: {
    TermsAndConditionsFooter,
    // Chatbot: Chatbot,
    // WhatsappComponent
  },
  computed: {
    isMobileView() {
      return this.$store.state.GeneralConfigStore.isMobileView;
    },
    showVideo() {
      if (this.$store.state.GeneralConfigStore.bgApp) {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations(["setIsUserSideBarOpen"])
  }
};
</script>



<style>
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--secondary-light);
  }
  ::-webkit-scrollbar {
    width: 10px;
    position: fixed;
    right: 0px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .app-container {
    min-height: 100vh;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.75s ease;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  .child-view {
    position: absolute;
    transition: all 0.75s cubic-bezier(0.55, 0, 0.1, 1);
  }
  
  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    -webkit-transform: translate(30px, 0);
    transform: translate(30px, 0);
  }
  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    -webkit-transform: translate(-30px, 0);
    transform: translate(-30px, 0);
  }

</style>